<script>
    import Monitor from '@/services/Monitor';
    import General from '@/services/General';
    import validationMessages from '@/components/validations';
    import { required, maxLength } from "vuelidate/lib/validators";

    export default {
        components:{
            validationMessages
        },
        data() {
            return {
                showLoader: false,
                showModal: false,
                tryingToSubmit: false,
                submitted: false,
                tenants:'',
                tenant: '' ,
                dashboard_url : '',
                dashboard_version : '',
                tenants_options:[],
                Data: [],
                csrf_token: localStorage.getItem('csrf_token'),
               
            };
        },
        validations: {
            tenant: {
                required,
            },
            dashboard_url: {
                required,
                maxLength: maxLength(100),
            }
        },
        methods: {
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            async getTenants() {
                try {
                    this.showLoader = true
                    const response = await Monitor.getTenants();
                    this.tenants_options = response.data.tenants.map(tenant => ({
                        text: tenant,
                        value: tenant
                    }));
                } catch (error) {
                    console.log('Error fetching tenants:', error);
                }finally {
                    this.showLoader = false
                }
            },

            async getTenantDashboards() {
                try {
                    const response = await Monitor.getTenantDashboards();
                    this.Data = response.data.data;
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.Data = []
                }
            },

            createTenantDashboard() {
                this.tryingToSubmit = true;
                this.submitted = true;
                this.$v.$touch();

                if (this.$v.$invalid || !this.tenant) {
                    this.tryingToSubmit = false;
                    return;
                } else {
                    this.getTenantDashboards()
                        .then(() => {

                            const requestData = {
                                tenant: this.tenant,
                                dashboard_url: this.dashboard_url,
                                dashboard_version: this.dashboard_version,
                            };

                            Monitor.createTenantDashboard(requestData)
                                .then((response) => {
                                    const res = response.data.data ? response.data.data : false;
                                    const error = response.data.error ? response.data.error : '';
                                    if (!res) {
                                        this.successmsg('Tenant Dashboard Created!');
                                        this.refreshData();
                                        this.closeModal();
                                    } else {
                                        this.failedmsg('Failed to create new Tenant Dashboard!')
                                    }
                                })
                                .catch(error => {
                                    const resData = error.response.data.error ? error.response.data.error : 'Failed';
                                    const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                                    this.failedmsg(errorMsg)
                                })
                                .finally(() => {
                                    this.tryingToSubmit = false;
                                });
                        })
                        .catch(error => {
                            console.error('Error fetching tenant dashboard:', error);
                            this.tryingToSubmit = false;
                        });
                }
            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.submitted = false
                this.tenant = ''
                this.dashboard_url=''
                this.dashboard_version=''
            },

            modalShown(){
                this.getTenants()
            }
        }
    };
</script>

<template>
    <b-modal @shown="modalShown" id="add_new_tenant_dashboard" v-model="showModal" @hidden="resetProps" title="Add New Tenant Dashboard" title-class="font-18" size="lg">
        <form @submit.prevent="createTenantDashboard" v-if="!showLoader">
            <div class="row">
                <div class="col-4">
                    <b-form-group label="Select Team ID" label-for="formrow-tenant-input" class="mb-3">
                        <b-form-select
                                v-model="tenant"
                                :options="tenants_options"
                                :class="{ 'is-invalid': submitted && $v.tenant.$error, }"
                                class="form-select"
                        ></b-form-select>
                        <validationMessages v-if="submitted" :fieldName="'Tenants'" :validationName="$v.tenant"></validationMessages>
                    </b-form-group>
                </div>
               
                <b-form-group label="Dashboard URL" label-for="formrow-title-input" class="mb-3">
                    <b-form-input
                            v-model.trim="dashboard_url"
                            type="text"
                            id="dashboard_url"
                            aria-describedby="title-feedback"
                            :class="{ 'is-invalid': submitted && $v.dashboard_url.$error, }"
                    >
                    </b-form-input>
                    <validationMessages v-if="submitted" :fieldName="'Dashboard URL'" :validationName="$v.dashboard_url"></validationMessages>
                </b-form-group>
                <b-form-group label="Dashboard Version" label-for="formrow-title-input" class="mb-3">
                    <b-form-input
                            v-model.trim="dashboard_version"
                            type="text"
                            id="dashboard_version"
                            aria-describedby="title-feedback"
                           
                    >
                    </b-form-input>
                </b-form-group>
            </div>
        </form>
        <div v-else>
            <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
                <div class="row">
                    <div class="col-12">
                        <div class="row  align-items-center justify-content-center">
                            <b-spinner large></b-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="createTenantDashboard" :disabled="tryingToSubmit || showLoader">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit
            </b-button>
        </template>
    </b-modal>
</template>
