<script>
import Monitor from "@/services/Monitor";
import validationMessages from '@/components/validations';
import { required } from "vuelidate/lib/validators";


export default {
    props: {
        tenantDashboardData: {
            type: Object,
            required: true,
            default: () => ({
                tenant: '',
                dashboard_url: '',
                dashboard_version: ''
            })
        }
    },
    data() {
        return {
            csrf_token: localStorage.getItem("csrf_token"),
            tryingToEdit: false,
            showModal: false,
            submitted: false,
            localEditData: { ...this.tenantDashboardData }
        };
    },
    watch: {
        tenantDashboardData: {
            immediate: true,
            handler(newValue) {
                this.localEditData = newValue ? { ...newValue } : {};
            }
        },
        showModal(newValue) {
            if (!newValue) {
                this.localEditData = { ...this.tenantDashboardData };
            }
        }
    },
    components: {
        validationMessages,  
    },
    validations: {
        localEditData: {
            dashboard_url: { required },

        }
    },
    methods: {
        refreshData() {
            this.$emit('onRefresh');
        },
        closeModal() {
            this.localEditData = { ...this.tenantDashboardData };
            this.showModal = false;
        },
        async editTenantDashboard() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.tryingToEdit = true;

            try {
                const tenant = this.localEditData.tenant;
                const response = await Monitor.updateTenantDashboard(tenant, this.localEditData);
                if (response.data.success) {
                    this.successmsg('Tenant Dashboard successfully edited');
                    this.closeModal();
                    this.refreshData();
                } else {
                    this.failedmsg(response.data.message || 'Failed to edit Tenant Dashboard!');
                }
            } catch (error) {
                const errorMsg = error.response && error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'Failed to update tenant dashboard';
                this.failedmsg(errorMsg);
            } finally {
                this.tryingToEdit = false;
            }
        }
    }
};
</script>
<template>
    <b-modal v-model="showModal" id="edit_tenant_dashboard" title="Edit Tenant Dashboard" title-class="font-18" centered
        size="lg">
        <form @submit.prevent="editTenantDashboard">
            <div class="mb-3"><h5>Team ID: {{localEditData.tenant}}</h5></div>
            <div class="mb-3">
                <label class="control-label form-label">Dashboard URL</label>
                <b-form-input v-model="localEditData.dashboard_url" type="text"
                            id="formrow-key-input"
                            aria-describedby="key-feedback"
                    :class="{ 'is-invalid': submitted && $v.localEditData.dashboard_url.$error, }"></b-form-input>
                    
                <validationMessages v-if="submitted && $v.localEditData.dashboard_url.$error"
                    :fieldName="'Dashboard URL'" :validationName="$v.localEditData.dashboard_url">
                </validationMessages>
            </div>
            <div class="mb-3">
                <label class="control-label form-label">Dashboard Version</label>
                <b-form-input v-model="localEditData.dashboard_version"></b-form-input>

            </div>
        </form>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editTenantDashboard" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change
            </b-button>
        </template>
    </b-modal>
</template>
